// @ts-ignore

import {useEffect, useState} from "react";
import CompanyProfitTableComponent from "./CompanyProfitTableComponent";
import {CircleSectorComponent} from "./CircleSectorComponent";
import {HistogramComponent} from "./HistogramComponent";

function ProblemComponent({appProblem, handleAnswer, finished, problemNum}) {
    const [chosenAnswer, setChosenAnswer] = useState("");

    // useEffect with console.log to check the value of appProblem
    useEffect(() => {
        console.log(appProblem);
        setChosenAnswer("");
    }, [appProblem]);

    let problemDescriptionClasses = finished ? appProblem.correct ? "problem correct" : "problem wrong" : "";
    let finishedProblemStatus = finished ? appProblem.correct ? " CORRECT" : " WRONG" : "";

    return (
        <>
            {appProblem && <div style={{width: "70%", height: "90%", marginTop: "20px"}}>
                {<div><p>{problemNum + 1}. {appProblem.description}<span
                    className={problemDescriptionClasses}>{finishedProblemStatus}</span>
                </p></div>}
                {/*Optional block that displays a table of company profits with company names as columns and years as rows for problemType COMPANY_PROFIT_TABLE*/}
                {appProblem.problemType === "COMPANY_PROFIT_TABLE" &&
                    <CompanyProfitTableComponent parameters={appProblem.parameters} prop1={(companyName, index) => {
                        return <th key={index}>{companyName}</th>
                    }}/>
                }
                {/*Optional block that displays (using react-chartjs-2) a bar chart with 3 bars for each country (food, housing and medical expenses) for problemType HISTOGRAM_COUNTRIES_ECONOMICS*/}
                {appProblem.problemType === "HISTOGRAM_COUNTRIES_ECONOMICS" &&
                    <HistogramComponent parameters={appProblem.parameters}/>
                }
                {/*Optional block that displays (using react-chartjs-2) table with 2 rows of pie charts with 8 sectors each of which one is filled and others - empty for problemType CIRCLE_SECTOR
                    First row consists of 4 pie charts with 8 sectors each of which one is filled and others - empty
                    Second row consists of 5 pie charts with 8 sectors each of which one is filled and others - empty
                */}
                {appProblem.problemType === "CIRCLE_SECTOR" &&
                    //width of the whole table should be no more than 80% of the screen
                    <CircleSectorComponent parameters={appProblem.parameters}/>
                }


                {<div>
                    {appProblem.answers.map((answer, index) => {

                        let buttonClasses = chosenAnswer === answer.text  && appProblem.chosenAnswer === answer.text ? "active" : undefined;
                        if (finished && appProblem.chosenAnswer === answer.text) {
                            buttonClasses = "active";
                            if (answer.correct) {
                                buttonClasses = buttonClasses + " correct";
                            } else {
                                buttonClasses = buttonClasses + " wrong";
                            }
                        } else if (appProblem.chosenAnswer === answer.text) {
                            buttonClasses = "active";
                        } else if (finished && answer.correct) {
                            buttonClasses = "active correct";
                        }

                        return <li key={index}>
                            <button disabled={finished} className={buttonClasses} onClick={() => {
                                setChosenAnswer(answer.text);
                                handleAnswer(answer.text);
                            }}>{answer.text}</button>
                        </li>
                    })}
                </div>}
            </div>}
        </>
    );
}

export default ProblemComponent;