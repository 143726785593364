import React, {useContext, useState, useEffect} from "react"
import {auth, db} from "./firebase"
import {collection, doc, getDoc} from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const subscriptionsCollection = collection(db, "subscription");
    const [subscriptionStatus, setSubscriptionStatus] = useState(false);
    const [testLoading, setTestLoading] = useState(false);

    function updateSubscriptionStatus(status) {
        setSubscriptionStatus(status);
    }

    function updateTestLoading(status) {
        console.log("Updating test loading status to: ", status);
        setTestLoading(status);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
            if (user) { // User is signed in
                const uid = user.uid;

                const subscriptionRef = doc(subscriptionsCollection, uid);
                getDoc(subscriptionRef).then((doc) => {
                    if (doc.exists()) {
                        const subscriptionData = doc.data();

                        // Check for active field (boolean or timestamp)
                        if (subscriptionData.subEnd && subscriptionData.subEnd.toDate() > new Date()) {
                            setSubscriptionStatus(true);
                            console.log("User has an active subscription!");
                        } else {
                            setSubscriptionStatus(false);
                            console.log("User's subscription is inactive.");
                        }
                    } else {
                        setSubscriptionStatus(false);
                        console.log("No subscription found for the user.");
                    }
                }).catch((error) => {
                    console.log("Error getting subscription:", error);
                });
            } else { // User is not signed in
                console.log("User is not signed in, cannot check subscription.");
            }
        })

        return unsubscribe
    }, [testLoading])

    const value = {
        currentUser,
        loading,
        subscriptionStatus,
        updateSubscriptionStatus,
        updateTestLoading,
        testLoading
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}