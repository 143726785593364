import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import validator from 'validator';
import {useAuth} from "../firebase/AuthContext";

function LoginComponent({onGoogleSignIn, onAppleSignIn, onEmailSignIn, onEmailSignUp}) {
    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const {currentUser} = useAuth();

    const onInvalidCredentials = () => {
        setEmailError('Invalid credentials');
        setPasswordError('Invalid credentials');
    }

    const validateEmail = (email) => {
        validator.isEmail(email) ? setEmailError('') : setEmailError('Email invalid');
    }

    const validatePassword = (password) => {
        password.length >= 6 && password.length < 20 ? setPasswordError('') : setPasswordError('Password length should be between 6 and 20 characters');
    }

    let emailLoginSignupDisabled = !(emailError.length === 0 && passwordError.length === 0 && inputEmail.length > 0 && inputPassword.length > 0);

    return !currentUser?.email ? <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around", height: "400px"}}>
        <TextField style={{width: 360}} error={emailError.length > 0}
                   helperText={emailError.length > 0 ? emailError : ''} id="outlined-email-input" label="Email"
                   type="email" variant="outlined"
                   value={inputEmail} onChange={(event) => {
            validateEmail(event.target.value);
            setInputEmail(event.target.value.trim());
        }}/>
        <TextField style={{width: 360}} error={passwordError.length > 0}
                   helperText={passwordError.length > 0 ? passwordError : ''} id="outlined-password-input"
                   label="Password" type="password" variant="outlined"
                   value={inputPassword} onChange={(event) => {
            validatePassword(event.target.value);
            setInputPassword(event.target.value);
        }}/>
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <Button disabled={emailLoginSignupDisabled} style={{width: 170}} variant="outlined" color="inherit" onClick={() => {
                onEmailSignIn(inputEmail, inputPassword, onInvalidCredentials);
            }}>Login
            </Button>
            <Button disabled={emailLoginSignupDisabled} style={{width: 170}} variant="outlined" color="inherit" onClick={() => {
                onEmailSignUp(inputEmail, inputPassword, onInvalidCredentials);
            }}>Create Account
            </Button>
        </div>
        <Button variant="outlined" color="inherit" onClick={onGoogleSignIn}>Sign In with Google</Button>
        <Button variant="outlined" color="inherit" onClick={onAppleSignIn}>Sign In with Apple</Button>
    </div> : <></>;
}

export default LoginComponent;