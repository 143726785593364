import React, {useState} from 'react';
import './App.css';
import {doSignInWithApple, doSignInWithGoogle, doSignOut} from "./firebase/auth";
import {logEvent} from "firebase/analytics";
import {Typography} from "@mui/material";
import FloatingMenuComponent from "./component/FloatingMenuComponent";
import {analytics, auth} from "./firebase/firebase";
import LoginComponent from "./component/LoginComponent";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";
import {AuthProvider} from "./firebase/AuthContext";
import MainBoardComponent from "./component/MainBoardComponent";
import {Problem} from "./model/Problem";

const backendUrl = 'https://vizitochka.net';

// const backendUrl = 'http://localhost:8080';

function App() {

    const [testState, setTestState] = useState({
        correctProblems: 0,
        percentage: 0,
        currentProblem: 0,
        finished: false,
        //problems array of Problem objects
        problems: [] as Problem[]
    });

    function resetTest() {
        setTestState({
            correctProblems: 0,
            percentage: 0,
            currentProblem: 0,
            finished: false,
            problems: []
        });
    }

    function restartTest(email: string) {
        logEvent(analytics, 'nonomind_restart_test', {email: email});
        loadWholeTest();
    }

    function updateResult() {
        setTestState((testState) => {
            return {
                ...testState,
                currentProblem: testState.currentProblem + 1,
                finished: testState.currentProblem >= testState.problems.length - 1
            };
        });
    }

    function nextQuestion(email: string) {
        logEvent(analytics, 'nonomind_next_question', {email: email});
        if (testState.currentProblem < testState.problems.length - 1) {
            updateResult();
        }
    }

    function previousQuestion(email: string) {
        logEvent(analytics, 'nonomind_previous_question', {email: email});
        if (testState.currentProblem > 0) {
            setTestState((testState) => {
                return {
                    ...testState,
                    currentProblem: testState.currentProblem - 1
                };
            });
        }
    }


    function handleAnswer(text: string) {
        let problem = testState.problems[testState.currentProblem];
        problem.chosenAnswer = text;
        setTestState({...testState, problems: testState.problems});
    }

    function loadWholeTest() {
        fetch(backendUrl + '/wholetest', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
            .then(response => {
                // If the response is not ok, throw an error
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Parse the response
                return response.json();
            })
            .then(data => {
                // Log the response
                console.log(data);
                setTestState({
                    ...testState,
                    finished: false,
                    correctProblems: 0,
                    percentage: 0,
                    currentProblem: 0,
                    problems: data
                });
            })
            .catch(error => {
                // Log the error
                console.error('There was a problem with the fetch operation:', error);
            });
    }

    function calculateCorrectAnswers() {
        let correctNum = 0;
        for (let i = 0; i < testState.problems.length; i++) {
            if (testState.problems[i].chosenAnswer === testState.problems[i].answers.find(answer => answer.correct)?.text) {
                correctNum++;
                testState.problems[i].correct = true;
            }
        }
        return correctNum;
    }

    function finishTest(email: string) {
        logEvent(analytics, 'nonomind_finish_test', {email: email});
        const correctNum: number = calculateCorrectAnswers();
        setTestState((testState) => {
            return {
                ...testState,
                correctProblems: correctNum,
                percentage: correctNum === 0 ? 0 : Math.round(correctNum / testState.problems.length * 100),
                currentProblem: testState.currentProblem,
                finished: true
            };
        });
    }

    const [isSigningIn, setIsSigningIn] = useState(false);
    //use state for current user
    const onSignInWithGoogle = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                await doSignInWithGoogle();
            } catch (e) {
                console.error(e);
            } finally {
                setIsSigningIn(false);
            }
        }
    }

    const onSignInWithApple = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                await doSignInWithApple();
            } catch (e) {
                console.error(e);
            } finally {
                setIsSigningIn(false);
            }
        }
    }

    const onSignOut = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (!isSigningIn) {
            try {
                resetTest();
                await doSignOut();
            } catch (e) {
                console.error(e);

            }
        }
    }

    const doSignInWithEmailPassword = (inputEmail: string, inputPassword: string, onInvalidCredentials: Function) => {
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                signInWithEmailAndPassword(auth, inputEmail, inputPassword)
                    .then()
                    .catch((error) => {
                        onInvalidCredentials();
                    });
            } catch (e) {
            } finally {
                setIsSigningIn(false);
            }
        }
    }
    const doCreateUserWithEmailPassword = (inputEmail: string, inputPassword: string, onInvalidCredentials: Function) => {
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                createUserWithEmailAndPassword(auth, inputEmail, inputPassword)
                    .then()
                    .catch((error) => {
                        onInvalidCredentials();
                    });
            } catch (e) {
            } finally {
                setIsSigningIn(false);
            }
        }
    }

    return (
        <div className="App">
            <AuthProvider>
                <div className="App-header">
                {testState.problems.length === 0 ? <Typography variant="h3" component="div" gutterBottom>
                        Welcome to nonomind
                    </Typography>
                    : <></>}

                <FloatingMenuComponent isTestFinished={testState.finished}
                                       isTestStarted={testState.problems.length > 0 && !testState.finished}
                                       onSignOut={onSignOut}
                                       finishTest={finishTest} restartTest={restartTest} goToMainBoard={resetTest}
                                       correctProblems={testState.correctProblems}
                                       totalNumberOfProblems={testState.problems.length}
                />
                <LoginComponent onGoogleSignIn={onSignInWithGoogle} onAppleSignIn={onSignInWithApple}
                                onEmailSignIn={doSignInWithEmailPassword}
                                onEmailSignUp={doCreateUserWithEmailPassword}/>
                <MainBoardComponent testState={testState} loadWholeTest={loadWholeTest}
                                    handleAnswer={handleAnswer} handlePreviousQuestion={previousQuestion}
                                    handleNextQuestion={nextQuestion}/>
                </div>
            </AuthProvider>
        </div>
    );
}

export default App;
