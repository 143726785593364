import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCZDGhQ5VfPf_NKYfdq2fDujEyP0QwPwfY",
    authDomain: "nonomind-d6f83.firebaseapp.com",
    projectId: "nonomind-d6f83",
    storageBucket: "nonomind-d6f83.appspot.com",
    messagingSenderId: "449786680869",
    appId: "1:449786680869:web:79cc09c79422df8f83d1cc",
    measurementId: "G-MH4X6SLCWK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);


export { app, auth, analytics, db };