import {Pie} from "react-chartjs-2";
import {Chart as ChartJS} from "chart.js/auto";

export function CircleSectorComponent(props) {
    let firstSectorNumber = props.parameters.question.firstSectorNumber;
    let secondSectorNumber = props.parameters.question.secondSectorNumber;
    let thirdSectorNumber = props.parameters.question.thirdSectorNumber;
    let fourthSectorNumber = props.parameters.question.fourthSectorNumber;

    let dataCircle1 = getCircleData(firstSectorNumber);
    let dataCircle2 = getCircleData(secondSectorNumber);
    let dataCircle3 = getCircleData(thirdSectorNumber);
    let dataCircle4 = getCircleData(fourthSectorNumber);

    let answerFirstSectorNumber = props.parameters.answer[0];
    let answerSecondSectorNumber = props.parameters.answer[1];
    let answerThirdSectorNumber = props.parameters.answer[2];
    let answerFourthSectorNumber = props.parameters.answer[3];
    let answerFifthSectorNumber = props.parameters.answer[4];

    let answerCircle1 = getCircleData(answerFirstSectorNumber);
    let answerCircle2 = getCircleData(answerSecondSectorNumber);
    let answerCircle3 = getCircleData(answerThirdSectorNumber);
    let answerCircle4 = getCircleData(answerFourthSectorNumber);
    let answerCircle5 = getCircleData(answerFifthSectorNumber);

    const topRowOptions = {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                enabled: false
            }
        },
        radius: 50
    }

    return (
        <>
        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
            <Pie className='circleSector' data={dataCircle1} options={{...topRowOptions}} style={{}}/>
            <Pie className='circleSector' data={dataCircle2} options={{...topRowOptions}} style={{}}/>
            <Pie className='circleSector' data={dataCircle3} options={{...topRowOptions}} style={{}}/>
            <Pie className='circleSector' data={dataCircle4} options={{...topRowOptions}} style={{}}/>
            <p
                style={{
                    fontSize: "2em",
                    margin: "0",
                    padding: "0",
                    width: "20%",
                    height: "100%",
                }}
            >
                ?
            </p>
        </div>
        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
            <Pie className='circleSector' data={answerCircle1} options={{...topRowOptions}} style={{}}/>
            <Pie className='circleSector' data={answerCircle2} options={{...topRowOptions}} style={{}}/>
            <Pie className='circleSector' data={answerCircle3} options={{...topRowOptions}} style={{}}/>
            <Pie className='circleSector' data={answerCircle4} options={{...topRowOptions}} style={{}}/>
            <Pie className='circleSector' data={answerCircle5} options={{...topRowOptions}} style={{}}/>
        </div>
            <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "center"}}>
                <div className='circleSectorNum'><p>1</p></div>
                <div className='circleSectorNum'><p>2</p></div>
                <div className='circleSectorNum'><p>3</p></div>
                <div className='circleSectorNum'><p>4</p></div>
                <div className='circleSectorNum'><p>5</p></div>
            </div>
        </>
    );
}

function getCircleData(sectorNumber) {
    return {
        datasets: [
            {
                data: [1, 1, 1, 1, 1, 1, 1, 1],
                borderColor: [
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                    'rgba(0, 0, 0, 1)',
                ],
                backgroundColor: [
                    //if sectorNumber is 1, then the first sector is filled, otherwise it is empty
                    sectorNumber === 1 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                    sectorNumber === 2 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                    sectorNumber === 3 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                    sectorNumber === 4 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                    sectorNumber === 5 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                    sectorNumber === 6 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                    sectorNumber === 7 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                    sectorNumber === 8 ? 'rgba(0,0,0,0.5)' : 'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 5,
            },
        ],
    };
}

