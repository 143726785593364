import {createContext, useEffect, useState} from 'react';
import {analytics, auth} from './firebase'
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    OAuthProvider
} from "firebase/auth";
import {perf} from "./performance";
import { trace } from "firebase/performance";
import { logEvent } from "firebase/analytics";


export const doSignInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.error(error);
    }
}

export const doCreateUserWithEmailAndPassword = async (email, password) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.error(error);
    }
}

export const doSignInWithGoogle = async () => {
    const t = trace(perf, "Google Login Trace");
    t.start();

    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);

    t.stop();
    logEvent(analytics, 'nonomind_google_sign_in');
    return result;
}

export const doSignInWithApple = async () => {
    const provider = new OAuthProvider('apple.com');
    const result = await signInWithPopup(auth, provider);

    logEvent(analytics, 'nonomind_apple_sign_in');
    return result;

}

export const doSignOut = async () => {
    try {
        await auth.signOut();
        logEvent(analytics, 'nonomind_sign_out');
    } catch (error) {
        console.error(error);
    }
}