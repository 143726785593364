import LogoutIcon from "@mui/icons-material/Logout";
import {Card, CardContent, Fab, Typography} from "@mui/material";
import React from "react";
import ReplayIcon from "@mui/icons-material/Replay";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import CountdownTimerComponent from "./CountdownTimerComponent";
import {useAuth} from "../firebase/AuthContext";

// @ts-ignore
function FloatingMenuComponent({isTestStarted, isTestFinished, onSignOut, finishTest, restartTest, goToMainBoard, correctProblems, totalNumberOfProblems}) {
    const {currentUser, testLoading, updateTestLoading, subscriptionStatus} = useAuth();
    return (
        <>
            {currentUser?.email ?
                <Card variant="outlined" sx={{position: 'fixed', right: 35, top: 35, maxWidth: 175, borderWidth: 0}}>
                    <CardContent>
                        <div style={{display: "flex", maxHeight: 160, flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                         <Fab color="default" size="medium" variant="extended" onClick={onSignOut}
                                           sx={{maxWidth: 150,  borderRadius: 1, boxShadow: 0, m: 1}}>
                            <LogoutIcon sx={{position: "absolute"}}/>
                        </Fab>
                        {currentUser?.email && isTestFinished ? <Fab color="default" size="medium" variant="extended" onClick={() => {
                            updateTestLoading(!testLoading);
                            if (subscriptionStatus) {
                                restartTest(currentUser.email);
                            } else {
                                goToMainBoard();
                            }
                        }}
                                                             sx={{
                                                                 maxWidth: 150, borderRadius: 1, boxShadow: 0, m: 1
                                                             }}>
                            <ReplayIcon sx={{position: "absolute"}}/>
                        </Fab> : <></>}
                        {currentUser?.email && isTestStarted
                            ? <Fab color="error" size="medium" variant="extended" onClick={() => finishTest(currentUser.email)}
                                                            sx={{
                                                                maxWidth: 150, borderRadius: 1, boxShadow: 0, m: 1
                                                            }}>
                            <SportsScoreIcon sx={{position: "absolute"}}/>
                            </Fab>
                        : <></>}
                            {currentUser?.email && isTestStarted ? <CountdownTimerComponent initialTime={60 * 15}/> : <></>}
                        {currentUser?.email && isTestFinished ? <><Typography sx={{
                                fontWeight: 'bold',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                fontSize: 14
                            }}>
                                Result: {Math.floor(correctProblems / totalNumberOfProblems * 100)}%</Typography>
                            <Typography sx={{
                                fontWeight: 'bold',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                fontSize: 14
                            }}>Correct: {correctProblems}/{totalNumberOfProblems}
                            </Typography></>
                            : <></>
                        }
                        </div>
                    </CardContent>
                </Card>
           : <></>}

        </>
    );
}

export default FloatingMenuComponent;