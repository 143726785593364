import {Button, Card, CardContent, Typography} from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import {doc, collection, setDoc} from "firebase/firestore";
import {db, auth} from "../firebase/firebase";
import {useAuth} from "../firebase/AuthContext";
import {useState} from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function SubscriptionComponent() {
    const {subscriptionStatus, updateSubscriptionStatus} = useAuth();
    const subscriptionsCollection = collection(db, "subscription");
    const [termsOpen, setTermsOpen] = useState(false);

    const handleTermsOpen = () => {
        setTermsOpen(true);
    };
    const handleTermsClose = () => {
        setTermsOpen(false);
    };

    async function addSubscription(uid: string, subType: string, subEnd: Date | null = null) {
        try {
            // Create a document reference with the user's uid
            const subscriptionRef = doc(subscriptionsCollection, uid);

            // Prepare subscription data object
            const subscriptionData = {
                subType,
                subEnd: subEnd || null, // Set subEnd to null if not provided
            };

            // Add the document to Firestore
            await setDoc(subscriptionRef, subscriptionData);
            updateSubscriptionStatus(true);
            console.log("Subscription added successfully!");
        } catch (error) {
            console.error("Error adding subscription:", error);
        }
    }

    return (
        <>
            <BootstrapDialog
                onClose={handleTermsClose}
                aria-labelledby="customized-dialog-title"
                open={termsOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Terms and Conditions
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        1. Introduction

                        This document outlines the terms and conditions ("Terms") that govern your use of the subscription service ("Service") offered by nonomind.com ("we," "us," or "our"). By subscribing to the Service, you agree to be bound by these Terms.
                    </Typography>
                        <Typography gutterBottom>
                        2. Definitions

                        "Subscription": A recurring billing plan that grants access to the Service.
                        "Content": All information, data, text, software, sound, images, videos, graphics, and other materials accessible through the Service.
                        "User": An individual or entity who subscribes to the Service.
                        </Typography>
                    <Typography gutterBottom>
                            3. Subscription and Fees

                        We offer various subscription plans with different features and pricing.
                        You can subscribe to the Service through our website or mobile application.
                        Subscription fees are charged in advance on a recurring basis (e.g., monthly, yearly).
                        We reserve the right to change subscription fees upon reasonable notice.
                    </Typography>
                    <Typography gutterBottom>
                        4. Payment Terms

                        You are responsible for all charges associated with your subscription, including taxes.
                        We accept payment through various methods (e.g., credit card, debit card).
                        Payment information is securely stored using a reputable payment processor.
                        If your payment fails, we may suspend or terminate your access to the Service.
                    </Typography>
                    <Typography gutterBottom>
                        5. Use of Service

                        You are granted a non-exclusive, non-transferable license to use the Service for your personal, non-commercial use.
                        You may not modify, reverse engineer, decompile, disassemble, or attempt to derive the source code of the Service.
                        You are solely responsible for any content you upload, post, or share through the Service.
                    </Typography>
                    <Typography gutterBottom>
                        6. Disclaimers and Warranties

                        THE SERVICE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                        WE DO NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.
                    </Typography>
                    <Typography gutterBottom>
                        7. Limitation of Liability

                        WE SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICE, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES.
                    </Typography>
                    <Typography gutterBottom>
                        8. Term and Termination

                        These Terms are effective upon your subscription to the Service and remain in effect until terminated by either party.
                        You may cancel your subscription at any time through your account settings.
                        We may terminate your subscription for any reason upon written notice.
                    </Typography>
                    <Typography gutterBottom>
                        9. Intellectual Property

                        The Service and all content therein are protected by intellectual property laws.
                        You may not use any trademarks or copyrights owned by us without our prior written consent.
                    </Typography>
                    <Typography gutterBottom>
                        10. Governing Law and Dispute Resolution

                        These Terms shall be governed by and construed in accordance with the laws of Ukraine.
                        Any dispute arising out of or related to these Terms shall be settled by the highest Commercial Court in Ukraine.
                    </Typography>
                    <Typography gutterBottom>
                        11. Entire Agreement

                        These Terms constitute the entire agreement between you and us regarding the Service and supersede all prior or contemporaneous communications and proposals.
                    </Typography>
                    <Typography gutterBottom>
                        12. Amendments

                        We may amend these Terms at any time by posting the amended terms on our website or mobile application.
                        Your continued use of the Service after the amended terms are posted constitutes your acceptance of the amendments.
                    </Typography>
                    <Typography gutterBottom>
                        13. Severability

                        If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.
                    </Typography>
                    <Typography gutterBottom>
                        14. Contact Us

                        If you have any questions regarding these Terms, please contact us at info@nonomind.com.

                        Please note: This is a minimal template and may not be suitable for all situations. You should consult with an attorney to ensure the Terms are appropriate for your specific service and comply with applicable laws.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleTermsClose}>
                        OK
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            {subscriptionStatus ? <></>
                : <div style={{display: 'flex', width: 700, height: 400, justifyContent: 'space-around'}}>
                    <Card>
                        <CardContent>
                            <div style={{
                                display: 'flex',
                                height: 350,
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 24
                                }}>
                                    Free
                                </Typography>
                                <Typography>
                                    Unlimited access (3 days)
                                </Typography>
                                <Typography>
                                    30+ types of problems
                                </Typography>
                                <Typography>
                                    AI-powered test generation
                                </Typography>
                                <Button variant="text" sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    textDecoration: 'underline',
                                    fontSize: 12
                                }} onClick={handleTermsOpen}>Terms and Conditions</Button>
                                <Button variant="outlined" color="inherit" onClick={() => addSubscription(
                                    auth.currentUser!.uid,
                                    'week',
                                    new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)
                                )}>Continue</Button>
                            </div>
                        </CardContent>
                    </Card>
                    <Card style={{backgroundColor: 'lightseagreen'}}>
                        <CardContent>
                            <div style={{
                                display: 'flex',
                                height: 350,
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 24
                                }}>
                                    $2 / Week
                                </Typography>
                                <Typography>
                                    Unlimited access
                                </Typography>
                                <Typography>
                                    30+ types of problems
                                </Typography>
                                <Typography>
                                    AI-powered test generation
                                </Typography>
                                <Button variant="text" sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    textDecoration: 'underline',
                                    fontSize: 12
                                }} onClick={handleTermsOpen}>Terms and Conditions</Button>
                                <Button variant="outlined" color="inherit" onClick={() => addSubscription(
                                    auth.currentUser!.uid,
                                    'week',
                                    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                                )}>Subscribe</Button>
                            </div>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <div style={{
                                display: 'flex',
                                height: 350,
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <Typography sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontSize: 24
                                }}>
                                    $5 / Month
                                </Typography>
                                <Typography>
                                    Unlimited access
                                </Typography>
                                <Typography>
                                    30+ types of problems
                                </Typography>
                                <Typography>
                                    AI-powered test generation
                                </Typography>
                                <Button variant="text" sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    textDecoration: 'underline',
                                    fontSize: 12
                                }} onClick={handleTermsOpen}>Terms and Conditions</Button>
                                <Button variant="outlined" color="inherit" onClick={() => addSubscription(
                                    auth.currentUser!.uid,
                                    'week',
                                    new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
                                )}>Subscribe</Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            } </>
    );
}

export default SubscriptionComponent;