export default function CompanyProfitTableComponent(props) {
    return <table className="profitTable" cellPadding = "10 px" >
        <thead>
        <tr>
            <th></th>
            {/*Company names array is taken from parameters map by key "companyNames" and corresponding profits by key "profits". Years start from 2018 for array index 0*/}
            {props.parameters.companyNames.map(props.prop1
            )}
        </tr>
        </thead>
        <tbody>
        {/*profits array consists of arrays of 4 numbers for 4 years of profits. Each array corresponds to a column in a table.*/}
        <tr>
            <td>2018</td>
            <td>{props.parameters.profits[0][0]}</td>
            <td>{props.parameters.profits[1][0]}</td>
            <td>{props.parameters.profits[2][0]}</td>
        </tr>
        <tr>
            <td>2019</td>
            <td>{props.parameters.profits[0][1]}</td>
            <td>{props.parameters.profits[1][1]}</td>
            <td>{props.parameters.profits[2][1]}</td>
        </tr>
        <tr>
            <td>2020</td>
            <td>{props.parameters.profits[0][2]}</td>
            <td>{props.parameters.profits[1][2]}</td>
            <td>{props.parameters.profits[2][2]}</td>
        </tr>
        <tr>
            <td>2021</td>
            <td>{props.parameters.profits[0][3]}</td>
            <td>{props.parameters.profits[1][3]}</td>
            <td>{props.parameters.profits[2][3]}</td>
        </tr>
        </tbody>
    </table>;
}