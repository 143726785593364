import {Bar} from "react-chartjs-2";
import {Chart as ChartJS} from "chart.js/auto";

export function HistogramComponent(props) {
    let labels = ['England', 'Portugal', 'Bangladesh', 'Brazil', 'Australia'];
    let foodExpenses = [
        props.parameters.countries['England'].food,
        props.parameters.countries['Portugal'].food,
        props.parameters.countries['Bangladesh'].food,
        props.parameters.countries['Brazil'].food,
        props.parameters.countries['Australia'].food
    ];
    let housingExpenses = [
        props.parameters.countries['England'].housing,
        props.parameters.countries['Portugal'].housing,
        props.parameters.countries['Bangladesh'].housing,
        props.parameters.countries['Brazil'].housing,
        props.parameters.countries['Australia'].housing
    ];
    let medicalExpenses = [
        props.parameters.countries['England'].medical,
        props.parameters.countries['Portugal'].medical,
        props.parameters.countries['Bangladesh'].medical,
        props.parameters.countries['Brazil'].medical,
        props.parameters.countries['Australia'].medical
    ];
    const data = {
        labels,
        datasets: [
            {
                label: 'Food',
                data: [...foodExpenses],
                backgroundColor: 'rgb(255, 99, 132)',
                stack: 'Stack 0',
            },
            {
                label: 'Housing',
                data: [...housingExpenses],
                backgroundColor: 'rgb(54, 162, 235)',
                stack: 'Stack 1',
            },
            {
                label: 'Medical',
                data: [...medicalExpenses],
                backgroundColor: 'rgb(75, 192, 192)',
                stack: 'Stack 2',
            },
        ]
    }
    const options = {
        barPercentage: 0.5,
        barThickness: 15,
        maxBarThickness: 15,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            tooltip: {
                enabled: false
            }
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    return <div style={{display: "flex", justifyContent: "center"}}><Bar className='histogram' data={data} options={options}/></div>
}