import {Problem} from "../model/Problem";
import ProblemComponent from "./ProblemComponent";
import React from "react";

// @ts-ignore
export function FinishedTestComponent({testState}) {
    return <>{testState.problems.map((problem: Problem, index: number) => {
        return <><ProblemComponent key={index} appProblem={problem} handleAnswer={() => {}}
                                   finished={true} problemNum={index}/></>
    })}</>;
}