import {Button} from "@mui/material";
import {logEvent} from "firebase/analytics";
import {analytics} from "../firebase/firebase";
import React from "react";
import {useAuth} from "../firebase/AuthContext";
import {TestComponent} from "./TestComponent";
import {FinishedTestComponent} from "./FinishedTestComponent";
import SubscriptionComponent from "./SubscriptionComponent";

// @ts-ignore
export default function MainBoardComponent({testState, loadWholeTest, handleAnswer, handlePreviousQuestion, handleNextQuestion}) {
    const {currentUser, subscriptionStatus} = useAuth();
    return currentUser?.email ? <>
        {testState.problems.length === 0
            ? <>
                <SubscriptionComponent />
                {subscriptionStatus
                ? <Button variant="outlined" color="inherit" onClick={() => {
                    logEvent(analytics, 'nonomind_start_test', {email: currentUser.email});
                    loadWholeTest();
                }}>Start Test</Button>
                : <></>}
            </>
            : !testState.finished
                ? <TestComponent testState={testState} handleAnswer={handleAnswer} handleNextQuestion={handleNextQuestion} handlePreviousQuestion={handlePreviousQuestion} />
                : <FinishedTestComponent testState={testState}/>
        }
    </> : <></>; //return closed
}