import ProblemComponent from "./ProblemComponent";
import {Button} from "@mui/material";
import React from "react";
import {useAuth} from "../firebase/AuthContext";

// @ts-ignore
export function TestComponent({testState, handleAnswer, handlePreviousQuestion, handleNextQuestion}) {
    const {currentUser} = useAuth();
    return <>
        <ProblemComponent appProblem={testState.problems[testState.currentProblem]}
                          handleAnswer={handleAnswer}
                          finished={testState.finished}
                          problemNum={testState.currentProblem}/>
        <div style={{display: "flex", justifyContent: "space-between", width: 350}}>
            <Button disabled={testState.currentProblem === 0}
                    sx={{position: "block", width: 100}} variant="outlined" color="inherit"
                    onClick={() => handlePreviousQuestion(currentUser.email)}>Previous</Button>
            <Button disabled={testState.currentProblem >= testState.problems.length - 1}
                    sx={{position: "block", width: 100}} variant="outlined" color="inherit"
                    onClick={() => handleNextQuestion(currentUser.email)}>Next</Button>
        </div>
    </>;
}